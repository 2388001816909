.view-public-blog,
#views-bootstrap-grid-1 {
	.row {
		.col-xs-12, 
		.col-sm-6, 
		.col-md-4,
		.col-md-6,
		.col-lg-3,
		.col-lg-6 {
			img {
				width: 100%;
				height: auto;
				max-width: 100%;
			}
		}
	}
}




.page-blog, .page-taxonomy-term {
	.breadcrumb, .title, .action-links {
		display: none;
	}
	
	.view-public-blog .view-content {
		clear: both;
	}
  .views-bootstrap-grid-plugin-style > .row {
    display: flex;
    display: -webkit-flex; /* necessary prefix? */
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; /* necessary prefix? */

    > div {
	    
      display: -webkit-flex; /* necessary prefix? */
      display: flex;
      flex-direction: column;
      padding-bottom: 25px; //needs to be same as .views-field-uid height
			
        // BLOG POST ONLY THEMING
        .blog {
          padding: 0 20px;
          height: 100%;
          border: 1px solid #B5B5B5;
          position: relative;
          padding-bottom: 50px;
          
          .views-field-field-quote-text,
          .views-field-field-quote-author {
            display: none;
          }
        }

	      .views-field-field-blog-image {
	        overflow: hidden;
	        margin: 0 -20px; // cover .sel-blog-wrapper padding

	        a {
		        img {

			        @include transition(all 1.5s);
		        }

		        &:hover {
			        img {
				        @include transform(scale(1.07));
			        }
		        }
	        }
	      }
      .views-field-field-category {
        font-size: 10px;
        font-style: italic;
        margin: 20px 0 0 0;
      }

      .views-field-title {
        // @include blog-margin;
        margin: 20px 0 20px 0;
        padding: 0 0 20px 0;
        font-size: 24px;
        border-bottom: 3px solid $brand-green;
      }
      .views-field-body {
        // @include blog-margin;
        margin-bottom: 25px;
        .views-more-link {
          display: block;
          margin: 20px 0;
        }
      }
      .views-field-tags {
	      @include box-sizing(border-box);
        font-size: 12px;
        padding: 15px 20px;
        margin: 0 -20px; // cover .sel-blog-wrapper padding
        background-color: $brand-green;
        color: #fff;
        position: absolute;
        bottom: 0;
        width: 100%;
        font-style: italic;
        a {
          color: #fff !important;
        }
        .glyphicon-tag {
          float: right;
          color: #E0E0E0;
          margin-top: 1px;
        }
      }
    }
  }

  .view-filters {
	  margin: 0 15px 25px 15px;
	  border-bottom: 3px solid $brand-green;
	  padding: 8px;
	  text-align: right;

	  form {
		  position: relative;
		  label.control-label {
			  color: #fff;
		  }

		  .views-submit-button {
				float: left;
				width: 10%;


				.form-submit {
					@include transition(background .5s);
				  color: #fff !important;
				  display: block;
				  font-size: 18px;
				  background: $brand-green;
				  border: 0;
					margin-top: 2px;
					height: 33px;
					cursor: pointer;
					width: 100%;
				}
		  }

			#edit-keywords-wrapper {
				@include box-sizing(border-box);
				width: 70%;
				margin-bottom: 0;
				padding-right: 0;
				
				label { display: none; }
				
				input {
					width: 100%;
					border: 1px solid #999;
					padding: 5px;
					font-size: 18px;
				}
			}
			
			#edit-spamicide {
				display: none;
			}

		  #edit-category-wrapper .form-item {
// 			  width: 20%;
			  
			  fieldset {
				  height: auto;
				  padding: 0;
				  border: 0;
				  background: transparent;
				  margin-bottom: 0;
				  margin-top: 2px;
			  }
			  
			  legend {
				  color: #fff;
				  font-size: 18px;
				  margin-left: 10px;
				  margin-right: 10px;
				  margin-top: 7px;
				  text-align: left;
				  font-family: "Droid Sans", sans-serif;
			  }
			  div.form-checkboxes {
				  @include box-shadow(0px 0px 20px 1px rgba(0, 0, 0, 0.2));
				  @include box-sizing(border-box);
				  background: #fff;
				  position: absolute;
				  top: 15px;
				  left: 0;
				  z-index: 100;
				  width: 300px;
				  padding: 30px 30px 15px 30px;

				  .form-item {
						display: block;
						margin-bottom: 15px;
						text-align: left;
						
						label, 
						input {

						}

						label {
							color: $brand-green;
							float: none;
							width: auto;
						}
						

					}
					&:before {
						@include box-shadow(-2px 2px -1px 1px rgba(0, 0, 0, 0.2));
						@include transform(rotate(45deg));
				    background-color: #fff;
				    content: "\00a0";
				    display: block;
				    height: 20px;
				    left: 0;
				    position: relative;
				    top: -40px;
				    width:  20px;
					}
			  }
		  }
	  }
  }
}



// Specific theming for the blog pages.

.node-type-public-blog {
	.field-name-body {
		margin-bottom: 25px;
	}
	
	h1 {
		margin-top: 0;
		font-size: 34px;
		@include md-min {
			font-size: 44px;			
		}
	}
	
	.submitted {
		margin: 10px auto 20px auto !important;
		color: #666;
		padding-bottom: 10px;
		border-bottom: 3px solid #669966;		
	}
	
	.breadcrumb {
		display: none;
	}
	
	.field-name-field-public-blog-image {
		margin-bottom: 25px;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	.field-name-body, 
	.field-name-body p {
		font-size: 16px;
		line-height: 29px;
	}
	
	h1,
	.submitted,
	.field-name-body,
	.field-name-field-public-blog-tags,
	.field-name-field-public-blog-category {
		width: 80%;
		margin: 0 auto;
	}
	
	.field-name-field-public-blog-tags,
	.field-name-field-public-blog-category {
		margin-bottom: 15px;
		margin-top: 15px;
		.field-items {
			.field-item {
				display: inline-block;
			}
			div:nth-child(n) {
				&:after {
					content: ',';
				}
			}
			div:only-child {
				&:after {
					content: '';
				}
			}
			div:last-child {
				&:after {
					content: '';
				}
			}
		}
	}
}

.page-taxonomy-term {
	.title {
		display: block;
		text-align: center;
		font-size: 34px;
		@include md-min {
			font-size: 44px;			
		}
		margin-bottom: 35px;
	}

	.views-bootstrap-grid-plugin-style > .row > div .blog {
		padding-bottom: 0;
	}
}