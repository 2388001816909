/*
@include sm-max {
	fieldset.form-wrapper {
		@include box-sizing(border-box);
		width: 100%;
		max-width: 100%;
		display: block;
		
		.form-item {
			@include box-sizing(border-box);
			max-width: 100%;
			width: 100%;
			
			.formtips-wrapper {
				@include box-sizing(border-box);
				max-width: 100%;
				width: 100%;				
			}
			
			label {
				@include box-sizing(border-box);
				max-width: 100%;
				float: none;
				width: 100%;
			}
		}
	}	
}
*/

@include sm-max {
	form {
		fieldset.form-wrapper {
			@include box-sizing(border-box);
			width: 100%;
			max-width: 100%;
			display: block;
		}	
	
		.form-text,
		.form-select,
		.password-strength {
			@include box-sizing(border-box);
			width: 100% !important;
			max-width: 100% !important;
			float: none;
		}		
	}
}



textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
-webkit-appearance: none;
border-radius: 0;
}

input {
	-webkit-appearance: false;
	-moz-appearance:    false;
}

#edit-submit {
	@include sm-max {
		@include border-radius(5px);
		background: $brand-green;
		padding: 10px 20px;
		color: #fff;
		border: 0;
		cursor: pointer;
		font-size: 14px;
		min-width: 50px;
		text-align: center;		
	}
}