body {
  background: #b9d6d4 url("../img/bg.jpg") left top;
  font-family: "Droid Sans";
}

#main a {
  color: #006633;
}

#main a:hover {
  text-decoration: underline;
}

.node-type-webpage h1,
.page-locations h1 {
  font-family: "Droid Serif";
  /* 	color: #a7b16b; */
  margin: 0 0 15px 0;
  text-transform: uppercase;
  font-size: 44px;
  text-align: center;
  border-bottom: 1px solid #ebe9dd;

  @include xs {
    font-size: 28px;
  }
}

.node-type-enterprise-blog h1 {
  @include xs {
    font-size: 28px;
  }
}

#edit-profile-member-field-my-mailing-address-is {
  border-bottom: 1px solid #ebe9dd;
  margin-bottom: 10px;
}

.contentcontainer {
  background: #fff;
  padding: 10px;
  color: #626262;
}

.page-profile-member #page-title {
  display: none;
}

.page-profile-member h1 {
  margin: 0;
  font-size: 48px;
  line-height: 48px;

  @include xs {
    font-size: 28px;
    line-height: 28px;
    text-align: center;
  }
}

.page-profile-member table td,
.page-profile-member table th {
  background: #ebe9dd;
  border-bottom: 1px solid #cbc8b9;
  font-size: 12px;
  padding: 5px;
}

fieldset {
  background: #fff;
  border: 1px solid #e0e0e0;
}

#homepagetextwrapper {
  @include sm-max {
    width: 100%;
  }

  width: 940px;
  position: relative;
  margin: 0 auto;

  #homepagetext {
    @include xs {
      max-width: 95%;
      height: auto;
      top: 25px;
    }

    @include sm {
      max-width: 50%;
      height: auto;
      top: 140px;
    }

    position: absolute;
    top: 165px;
    left: 0;
    z-index: 50;
  }
}

#homepagevideo {
  position: absolute;
  top: 260px;
  left: 0;
  z-index: 100;
  display: block;
}

#homepagelocations {
  @include xs {
    top: 130px;
    left: 10px;
    width: 95%;
  }

  @include sm {
    top: 60px;
    left: 50%;
    width: 50%;
  }

  img {
    @include xs {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    @include sm {
      max-width: 95%;
      width: 100%;
      height: auto;
    }
  }

  top: 70px;
  left: 560px;
  position: absolute;
  z-index: 50;
}

fieldset legend {
  font-family: "Droid Serif";
}

.view-profile-blocks {
  padding: 10px;
  background: #fff;
}

.page-profile-member table {
  margin-bottom: 0;
}

.page-profile-member table th {
  font-weight: bold;
  width: 230px;
}

#block-views-profile-blocks-heading,
#block-views-profile-blocks-heading .view-profile-blocks,
#block-views-profile-blocks-main-photo,
#block-views-profile-blocks-main-photo .view-profile-blocks,
#block-views-profile-blocks-all-photos,
#block-views-profile-blocks-all-photos .view-profile-blocks {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
}

#block-views-profile-blocks-all-photos {
  margin-bottom: 1.5em;
}

#block-views-profile-blocks-main-photo img,
#block-views-profile-blocks-all-photos .view-profile-blocks .field-item a img {
  border: 1px solid #e1e2e2;
}

#block-views-profile-blocks-all-photos .view-profile-blocks .field-item a {
  display: inline-block;
}

#block-views-profile-blocks-address,
#block-views-profile-location-map {
  background: #fff;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  padding: 10px;
}

#block-views-profile-blocks-address .view-profile-blocks {
  background: transparent;
  border: 0;
  padding: 0;
}

#block-views-profile-blocks-address .view-profile-blocks .views-field {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0;
}

#block-views-profile-blocks-address h3 {
  margin-top: 0;
  font-size: 14px;
  font-weight: bold;
  font-family: "Droid Sans";
}

.spouseinfo {
  font-weight: bold;
  margin: 0;
  color: #9c9c9c;
  font-size: 16px;
  display: inline;
  position: relative;
  top: -10px;
  left: 0;
  text-transform: uppercase;
  font-family: "Droid Sans", sans-serif;
}

.profile_located-in {
  font-size: 12px;
}

.headercontainer {
  margin: 0 auto;
  padding: 0 10px;
  width: 940px;
  position: relative;
}

#logo {
  margin: 18px 0 10px 0;
  display: block;
  width: 241px;
}

#header {
}

#headerabove {
  background: #fff;
  position: relative;
  border-bottom: 1px solid #a7c2c0;
  height: 40px;
}

.not-logged-in #headerabove {
  height: 45px;
}

#header-search {
}

#navigation {
  position: absolute;
  top: 11px;
  right: 10px;
  color: #7d7267;
  font-size: 13px;
  z-index: 100;
}

#navigation a {
  color: $brand-green;
  text-decoration: underline;
}

#joinnow {
  background: $brand-green;
  color: #fff !important;
  text-decoration: none !important;
  padding: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  display: inline-block;
}

#navigation a:hover {
  text-decoration: none;
}

#nav_internal {
  position: absolute;
  top: 10px;
  right: 10px;
}

#nav_internal a {
  display: block;
  height: 110px;
  width: 110px;
  margin: 0 18px 0 0;
  float: left;
}

#nav_internal a.last {
  margin: 0;
}

#footer {
  font-size: 12px;
  color: #333;
}

#footer a {
  color: $brand-green;
  text-decoration: underline;
}

#footer a:hover {
  text-decoration: none;
}

#footer #copyright {
  float: left;
  padding-left: 15px;
}

#footer #footer_nav {
  float: right;
  padding-right: 15px;
}

ul.primary,
.tabs-wrapper {
  border-bottom: 0;
  margin: 0;
  position: relative;
  left: 0;
  top: 0px;
}

.page-profile-member-edit .tabs-wrapper {
  border-bottom: 1px solid #e2e2e2;
}

ul.primary li a {
  background: #f3efe9;
  color: #626262;
  border: 1px solid #e2e2e2;
}

ul.primary li a:hover,
ul.primary li a.active {
  background: #fff;
  color: #626262;
  border: 1px solid #e2e2e2;
  border-bottom: 1px solid #fff;
}

.page-lounge h1 {
  margin: 10px 0 10px 0;
  text-align: center;
  padding: 0 0 0px 0;
  line-height: 30px;
  font-size: 40px;
  text-transform: uppercase;

  @include xs {
    font-size: 24px;
    line-height: 24px;
  }
}

.page-lounge p.headertext {
  line-height: 12px;
  font-size: 12px;
  margin: 0 0 10px 0;
  padding: 0;
  text-align: center;
}

strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
.small {
  font-size: 10px;
}

#block-block-4 {
  width: 320px;
  margin: 0;
}

#block-block-4 iframe {
  background: #fff;
}

#block-egc-extras-fb-gallery {
  width: 320px;
}

#block-egc-extras-fb-gallery h2 {
  margin: 0 0 10px 0;
}

#block-views-from-the-blog-block-1,
#block-aggregator-feed-2 {
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 10px;
  width: 290px;
}

#block-aggregator-feed-2 {
  float: left;
  margin: 0 10px;
  width: 270px;
}

#block-aggregator-feed-2 .more-link {
  display: none;
}

#block-views-from-the-blog-block-1 h2,
#block-aggregator-feed-2 h2 {
  margin: 0;
}

#block-views-from-the-blog-block-1 ul,
#block-aggregator-feed-2 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#block-views-from-the-blog-block-1 li,
#block-aggregator-feed-2 li {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  border-bottom: 1px solid #e2e2e2;
  font-size: 12px;
}

#block-block-5 {
  float: right;
  margin-top: 24px;
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 10px;
  width: 550px;
}

#block-block-5 ul {
  padding: 0;
  list-style: none;
}

#block-block-5 li {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  border-bottom: 1px solid #e2e2e2;
  font-size: 12px;
}

#block-block-5 h2 {
  margin: 0;
}

tr.even,
tr.odd {
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
}

tr.even td,
tr.odd td {
  font-size: 12px;
  padding: 5px;
}

#block-views-exp-members-search-page,
#block-views-exp-members-search-page-1 {
  padding: 10px;
  margin-bottom: 0;
}

#block-views-exp-members-search-page input.form-text,
#block-views-exp-members-search-page-1 input.form-text {
  width: 200px;
}

#sidebar-first {
  border: 1px solid #e2e2e2;
  background: #fff;
}

.page-profile-member #sidebar-first {
  background: transparent;
  border: 0;
}

.tabledrag-toggle-weight-wrapper {
  display: none;
}

#block-block-7 {
  margin: 0;
}
#block-block-7 p {
  font-size: 12px;
  text-align: right;
  margin: 0;
}

.group_phone_1_group {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.group_phone_2_group {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.group_phone_3_group {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

#edit-profile-member {
  padding: 0;
  border: 0;
  background: transparent;
}
#edit-profile-member legend {
  display: none;
}
.showlegend legend {
  display: block !important;
}

/*
.field-type-phone-number {
	float: left;
}
*/

/*
.field-name-field-phone-1-type,
.field-name-field-phone-2-type,
.field-name-field-phone-3-type {
	float: left;
	margin: 7px 0 0 10px;
}
*/

/*
.group-member .form-item label,
.group-member .form-item .description,
.group-about-home .form-item label,
.group-about-home .form-item .description {
	display: inline-block;
	width: 300px;
}
*/

/*
.group-about-home .form-item .form-checkboxes,
.group-member .form-item .form-checkboxes {
	display: inline-table;
	width: 400px;
}

#edit-profile-member-field-bed-types-und .form-type-checkbox,
.group-about-home .form-item .form-checkboxes .form-type-checkbox {
	display: inline-block;
	width: 170px;
}

.group-about-home .form-item .form-checkboxes label.option,
.group-member .form-item .form-checkboxes label.option {
	width: auto !important;
	display: inline;
}

.page-user-register .group-account legend {
	display: none;
}

.page-user-register .group_home_hosting {
	background: #fff;
	border: 1px solid #e0e0e0;

}

.page-user-register legend {
	font-size: 24px;

}

.page-profile-member-edit legend {
	font-size: 18px;
}

.page-profile-member-edit .group_home_hosting fieldset,
.page-profile-member-edit  .group_phone_1_group,
.page-profile-member-edit  .group_phone_2_group,
.page-profile-member-edit .group_phone_3_group {
	margin: 1.5em;
}

.page-profile-member-edit .form-text {
	border: 1px solid #e0e0e0;
	padding: 5px;
	font-size: 15px;
}
*/

.redirect-list,
.page-profile-member-edit .file,
.page-profile-member-edit .file-size {
  display: none;
}

.page-blog h1 {
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
}

.page-blog .node-enterprise-blog {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.page-blog .node-enterprise-blog h2 {
  font-size: 18px;
  font-family: "Droid Serif";
}

.page-blog .node-enterprise-blog .field-name-body {
  font-size: 14px;
}

.page-blog .node-enterprise-blog .submitted,
.page-blog .node-enterprise-blog .links,
.node-type-enterprise-blog .submitted,
.node-type-enterprise-blog .links,
.node-type-enterprise-blog .field-name-field-tags,
.node-type-enterprise-blog .field-name-field-categories {
  font-size: 12px;
}

.node-type-enterprise-blog .field-name-field-categories {
  clear: both;
}
.node-type-enterprise-blog .field-name-field-categories .field-item {
  display: inline;
  padding: 0 5px 0 0;
}

.page-profile-member-edit #main {
  font-size: 12px;
}

#header_social {
  width: 300px;
  padding-top: 10px;
}

.not-logged-in #header_social {
  padding-top: 13px;
}

#header-social a {
  display: block;
  float: left;
  height: 17px;
  width: 17px;
}
.panel-2col-stacked .panel-col-first {
  width: 65%;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    padding-right: 0;
  }
}

.panel-2col-stacked .panel-col-last {
  width: 34%;
  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    padding-right: 0;
  }
}

.page-lounge .panel-2col-stacked .panel-col-first {
  width: 35%;
  padding-right: 15px;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    padding-right: 0;
  }
}

.page-lounge .panel-2col-stacked .panel-col-last {
  width: 60%;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    padding-right: 0;
  }
}

.page-lounge .panel-2col-stacked .panel-col-first iframe {
  @include xs {
    @include box-sizing(border-box);
    width: 100% !important;
  }
}

.page-profile-member .tabs,
.page-profile-member .tabs-wrapper,
.entity.entity-profile2.profile2-member {
  display: none;
}

#profile_header-left {
  float: left;
  position: relative;
  width: 130px;
  height: 130px;
  padding-right: 20px;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    float: none;
    height: auto;
    padding-right: 0;
  }

  img {
    @include xs {
      margin: 0 auto 15px auto;
      display: block;
    }
  }
}

#profile_header-right {
  float: left;
  width: 700px;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    float: none;
  }
}

#profile_header-image-mask {
  background: url("../img/profile_picture-mask.png") left top no-repeat;
  width: 130px;
  height: 130px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.tiny-image-mask {
  background: url("../img/tiny_picture-mask.png") left top no-repeat;
  width: 57px;
  height: 57px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

#annualfee {
  text-align: center;
  font-size: 24px;
  font-family: "Droid Serif";
}

.view-recent-images .field-content {
  position: relative;
  text-align: center;
}

.view-recent-images .field-content #profile_header-image-mask {
  left: 26px;
}

.pane-views-recent-images-block h2.pane-title {
  margin: 0 0 15px 0 !important;
}

.tinyframe {
  position: relative;
  display: inline-block;
}

.view-display-id-heading {
  padding: 0;
  border: 0;
}

.profile_meta {
  text-transform: uppercase;
  font-size: 12px;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
}

.profile_last-online {
  background: $brand-green;
  color: #fff;
  padding: 3px 5px;
  margin: 0 5px;
  display: inline-block;

  @include xs {
    display: block;
    margin-top: 10px;
  }
}

#profile_header-right p {
  font-family: "Droid Serif", serif;
  font-size: 14px;
  line-height: 18px;
  color: #4d4d4d;
  margin-top: 5px;

  @include xs {
    text-align: center;
  }
}

#block-panels-mini-profile h2 {
  background: #b9d5d3;
  color: #1289aa;
  padding: 12px 12px 12px 50px;
  margin-bottom: 10px;
  margin-top: 0;
}

.pane-views-profile-blocks-about-my-home h2 {
  background: #b9d5d3 url("../img/icon-home.png") left top no-repeat !important;
}

.pane-views-profile-blocks-block h2 {
  background: #b9d5d3 url("../img/icon-about.png") left top no-repeat !important;
}

.view-display-id-all_photos {
  border: 0;
  padding: 0;
}

.panel-col-first .view-profile-blocks {
  background: #ebe9dd;
}

.view-display-id-block_1 {
  border: 1px solid #b9d5d3;
  text-align: center;
  font-size: 14px;
  padding: 0px 0 15px 0;
  font-weight: bold;
}

.pane-views-profile-blocks-block-1 .view-display-id-block_1 .view-content h3 {
  background: #b9d5d3 url("../img/icon-contact.png") left -1px no-repeat !important;
  padding: 8px 8px 8px 37px;
  color: #1289aa;
  text-align: left;
  margin-top: 0;
  margin-bottom: 15px;
}

.pane-views-classifieds-block-1 .view-display-id-block_1 {
  padding: 20px !important;
}

.pane-views-profile-blocks-block-1 .view-display-id-block_1 .view-content a {
  display: block;
  padding: 5px;
  margin-top: 15px;
  background: url("../img/flag.png") center top no-repeat;
  color: #96927d !important;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Droid-Sans", sans-serif;
  margin: 10px 15px 0 15px;
  height: 24px;
}

.view-display-id-address {
  padding: 0;
}

.adr {
  font-size: 12px;
}

.view-display-id-directory_preview {
  border: 1px solid #b9d5d3;
  font-size: 11px;
}

.view-display-id-profilemap {
  padding: 0;
  background: transparent;
}

#block-panels-mini-profile {
  margin-bottom: 0;
}

#main {
  display: block;
}

#profile_tabs {
  background: url("../img/profile_tabbg.png") left bottom repeat-x;
  padding: 0 10px 0 10px;
  height: 36px;
  @include xs {
    height: auto;
    background: #ebe9dd;
    padding: 5px;
  }
}

#profile_tabs a {
  font-size: 12px;
  text-transform: uppercase;
  color: #626262;
  text-decoration: none;

  padding: 10px 10px 5px 30px;

  @include xs {
    border-bottom: 1px solid #cbc8b9;
    display: block;
  }
}

#profile_tabs a:hover {
  text-decoration: underline;
}

#profile_tabs a.active-tab {
  background: #fff url("../img/icon-white-house.png") 10px 7px no-repeat;
  font-size: 14px;
  padding: 10px 10px 5px 30px;
  border: 1px solid #cbc8b9;
  border-bottom: 1px solid #fff;
  position: relative;
  top: 0px;
  left: 0;
  color: #1289aa !important;
  text-decoration: none;

  @include xs {
    border-bottom: 1px solid #cbc8b9;
  }
}

.profile_profile {
  background: url("../img/icon-tan-house.png") 1px 8px no-repeat;
  padding-left: 20px;
  margin-left: 10px;
  padding-top: 1px;
  padding-bottom: 2px;

  @include xs {
    margin-left: 0;
    margin-right: 0;
    background: url("../img/icon-tan-house.png") 7px 10px no-repeat;
  }
}

#profile_tabs a.profile_commments {
  @include xs {
    margin-left: 0;
    margin-right: 0;
    background: url("../img/icon-tan-chat.png") 1px 3px no-repeat;
  }

  background: url("../img/icon-tan-chat.png") left top no-repeat;
  padding-left: 20px;
  margin-left: 10px;
  padding-top: 1px;
  padding-bottom: 2px;
}

#profile_tabs a.profile_edit {
  background: url("../img/icon-tan-profile.png") left top no-repeat;
  padding-left: 20px;
  padding-top: 1px;
  padding-bottom: 2px;
  @include xs {
    margin-left: 0;
    margin-right: 0;
    background: url("../img/icon-tan-profile.png") 1px 3px no-repeat;
    border-bottom: 0;
  }
}

.profile_account {
  background: url("../img/icon-tan-settings.png") left top no-repeat;
  padding-left: 20px;
  padding-top: 1px;
  padding-bottom: 2px;
  margin-left: 10px;
}

#profile_tabs a.profile_commments.active-tab {
  background: #fff url("../img/icon-white-chat.png") 10px 7px no-repeat !important;
  padding: 10px 10px 5px 30px;
}
.profile_tabs-left {
  position: relative;
  top: 10px;
  left: 0;

  @include xs {
    top: 0;
  }
}

.profile_tabs-right {
  float: right;
  position: relative;
  top: -13px;
  left: 0;

  @include xs {
    float: none;
    top: 0;
  }
}

.panel-col-top .panel-separator {
  display: none;
}

.page-profile-member .action-links {
  display: none;
}

.view-comments .views-field-field-photos {
  width: 47px;
}

.page-profile-member.view-header {
  background: #b9d5d3;
  padding: 8px;
  color: #1289aa;
  text-align: left;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: "Droid Serif", serif;
}

.page-profile-member.view-header a {
  float: right;
}

.page-profile-member .views-field-nothing .field-item {
  text-transform: uppercase;
}

.page-profile-member .views-field-nothing .field-item p {
  text-transform: none;
  font-size: 18px;
  font-family: "Droid Serif", serif;
  font-style: italic;
}

.page-profile-member-comments td,
.page-profile-member-feedback td {
  background: #fff !important;
}

#block-panels-mini-clone-of-profile {
  margin-bottom: 0;
}

.front h1 {
  display: none;
}

/*
.not-logged-in .contentcontainer {
	padding: 0 0 10px 0;
	margin: 0 auto;
	width: 940px;
	border: 0;
}
*/

/* #rotator { width: 940px; margin: 0 auto; } */

#block-block-3 {
  background: url("../img/as-seen-on-bg.png") left top repeat-x;
  text-align: center;
  margin-bottom: 5px;
}

#block-block-3 p {
  margin: 0;
}

#block-block-1 {
  width: 700px;
  margin: 0;
  float: left;
  margin-left: auto;
}

#as_seen_on {
  width: 100%;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  height: 68px;

  @include sm-max {
    display: none;
  }
}

#as_seen_on .block {
  margin: 0;
}

#block-block-1 p {
  padding: 0;
  margin: 0;
}

#below_content {
  background: #fff;
  padding: 0 15px 15px 15px;
}

#block-block-1 .testimonial-bubble {
  width: 654px;
  height: 222px;
  margin: 0;
  font-family: "Droid Serif", serif;
  font-style: italic;
  font-size: 12px;

  @include sm-max {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: auto;
  }
}

#block-block-1 .testimonial-bubble strong {
  font-size: 24px;
  line-height: 36px;
  border-top: 1px solid #e2e2e2;
  height: auto;
  clear: both;
  display: block;
  padding: 5px 0;
  margin-bottom: 5px;
}

#block-block-1 .testimonial-bubble p {
  margin-bottom: 10px;
}

#block-views-from-the-blog-block {
  font-size: 14px;
  float: left;
  width: 240px;
  margin: 0;
  padding: 0 15px;
}

#block-views-from-the-blog-block h2 {
  margin-top: 0;
}

#block-block-2 {
  width: 238px;
  margin: 0;
  height: 214px;
  float: left;

  @include sm-max {
    display: none;
  }
}

.fluid {
  width: 100% !important;
  padding: 0 !important;
  background: #fff;
}

#below_content .contentcontainer {
  padding: 15px;
  margin: 0 auto;
  width: 938px;
  border: 0;

  @include sm-max {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}

.fluidcontainer {
  width: 940px;
  margin: 0 auto;
  padding: 0 15px;

  @include sm-max {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}

#container.fluid .contentcontainer {
  padding: 0;
}

.not-logged-in #rotator,
.node-type-webpage #rotator,
.page-locations #rotator {
  height: 170px;
  overflow: hidden;
}

#rotator {
  background: #fff;
  position: relative;
}
#rotator .block {
  margin-bottom: 0;
}
.front #rotator {
  @include xs {
    height: $mobile-slider-height;
  }
  height: 400px;
}
.view-id-image_slider {
  height: 170px;
}

.not-logged-in #below_content,
.node-type-webpage #below_content,
.page-locations #below_content {
  margin: 0 auto;
  width: 940px;
  @include sm-max {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  border: 0;
}

.pane-views-profile-blocks-block-1 .field-content a {
  color: rgb(98, 98, 98) !important;
}

.front #container {
  display: none;
}

.not-logged-in #logo {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 40;

  @include sm-max() {
    background: url("../img/logo-black.png") left top -4px no-repeat;
    width: 222px;
    height: 59px;
    background-size: cover;
  }

  img {
    width: 222px;
    height: auto;

    @include sm-max() {
      display: none;
    }
  }
}

.not-logged-in #container {
  width: 100%;
  padding: 0;
  background: #fbfbf8;
  border-top: 1px solid #a7c2c0;
}

.not-logged-in #container .contentcontainer {
  @include box-sizing(border-box);
  max-width: 100%;
  width: 940px;
  padding: 10px;
  margin: 0 auto;
  border: 0;
  background: transparent;

  @include xs {
    padding: 10px 20px;
  }
}

.logged-in {
  #logo {
    img {
      width: 222px;
      height: auto;
    }
  }
}

.form-type-textfield label,
.form-type-password label,
.formtips-wrapper label,
.form-type-textarea label,
.form-type-select label,
.form-type-checkboxes label,
fieldset.location .form-item label {
  @include sm-min {
    float: left;
    width: 400px;
  }
}

#user-login,
#forgot-username-form,
#user-pass {
  .form-type-textfield label,
  .form-type-password label,
  .formtips-wrapper label,
  .form-type-textarea label,
  .form-type-select label,
  .form-type-checkboxes label,
  fieldset.location .form-item label {
    @include sm-min {
      float: left;
      width: 150px;
    }
  }
}

.form-type-checkboxes .form-checkboxes label {
  float: none;
  width: auto;
}

.location .fieldset-description {
  font-style: italic;
  font-size: 12px;
}

.group-spouse-significant-other {
  display: none;
}

.form-wrapper {
  clear: both;
}
/*
.form-type-textfield .form-text,
.form-item-profile-member-field-phone-number-und-0-country-codes,
#edit-profile-member-field-phone-number-und-0-number {
	float: left;
}
*/
.page-user-register .tabs {
  display: none;
}

.password-parent {
  width: 100%;
}

.password-strength {
  margin-top: 0;
}

.form-item-pass-pass2 label {
  @include sm-min {
    float: left;
    width: 400px;
  }
}

.form-item-pass-pass2 input {
  @include sm-min {
    float: left;
    width: 176px;
  }
}

.confirm-parent,
.password-parent {
  width: 100%;
}

div.password-confirm {
  position: absolute;
  top: 15px;
  right: 0;
}

div.form-item div.password-suggestions {
  margin-top: 30px;
  font-size: 12px;
}

.page-user-register h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 50px;
}

.grippie {
  display: none;
}

.form-textarea-wrapper {
  width: 423px;
  margin-left: 2px;
  margin-bottom: 5px;
  float: left;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    max-width: 100%;
    float: none;
  }
}

.form-item-profile-member-field-amenities-available-option-und
  .formtips-wrapper
  label {
  @include sm-min {
    height: 200px;
  }
}

.form-item-profile-member-field-bed-types-und .formtips-wrapper label {
  @include sm-min {
    height: 70px;
  }
}

.form-item-profile-member-field-amenities-available-option-und
  .form-type-checkbox {
  @include sm-min {
    width: 200px;
    float: left;
  }
}

.form-item-profile-member-field-bed-types-und .form-type-checkbox {
  @include sm-min {
    width: 140px;
    float: left;
  }
}

#edit-legal p {
  clear: both;
  margin-top: 15px;
}

/* .form-item-profile-member-field-phone-number-und-0-country-codes { float: left; width: 100px; } */

.form-phone-number {
  @include sm-min {
    float: left;
    width: 200px;
  }
}

.form-item-profile-member-field-other-areas-of-interest-und label {
  @include sm-min {
    height: 80px;
  }
}

.form-item-profile-member-field-other-areas-of-interest-und
  .form-checkboxes
  label {
  height: auto;
}

/*
.form-item-profile-member-field-phone-number-und-0-country-codes select {
	width: 100px;
}
*/

#edit-profile-member-field-mailing-address-und-0,
#edit-profile-member-field-mailing-af-address-und-0 {
  display: block;
}

#cardimages {
  @include sm-min {
    margin-left: 400px;
  }
}

fieldset.location {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0;
}

fieldset.location legend {
  display: none;
}

#edit-timezone {
  display: none;
}

.page-checkout
  .form-item-commerce-payment-payment-details-cardonfile-instance-default {
  display: none !important;
}
.form-item-commerce-payment-payment-method {
  display: none;
}

.pane-views-welcome-our-new-members-block,
#block-egc-renewals-expiration-date-cost {
  background: #feedc4;
  border: 1px solid #dfc586;
  padding: 20px;
  text-align: center;
  color: #927a41;
}

.pane-views-welcome-our-new-members-block h2 {
  margin: 0;
  padding: 0;
  color: #463712;
}

.pane-egc-renewals-expiration-date h3 {
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: #8c9460;
}

.pane-views-welcome-our-new-members-block .view-content {
  font-size: 14px;
  font-style: italic !important;
}

.pane-egc-renewals-expiration-date .pane-content p {
  margin-bottom: 0;
  font-size: 12px;
}

.pane-views-welcome-our-new-members-block .view-content a,
.pane-egc-renewals-expiration-date .view-content a {
  color: #927a41 !important;
}

.pane-egc-renewals-expiration-date {
  background: #eceadf;
  padding: 10px;
  text-align: center;
}

.view.view-from-the-blog.view-display-id-block_1 {
  border: 0;
}

.view.view-from-the-blog.view-display-id-block_1 .view-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.page-user h1#page-title,
#edit-field-enterprise-blog-picture,
.logged-in #edit-legal,
#edit-field-enterprise-blog-firstname,
#edit-field-enterprise-blog-lastname {
  display: none;
}

#block-commerce-cart-cart {
  background: #feedc4;
  border: 1px solid #dfc586;
  padding: 10px;
  text-align: center;
  color: #927a41;
  margin: 0;
}

.page-checkout h1 {
  display: none;
}

#block-commerce-cart-cart h3 {
  margin: 0;
  padding: 0;
  color: #463712;
}

#block-commerce-cart-cart p {
  margin: 0;
}

#block-commerce-cart-cart p a {
  color: #927a41;
}

.tinyframe img {
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;

  @include xs {
    width: 70px;
    height: auto;
  }
}

#profile_header-left img {
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  border-radius: 50em;
}

#mini-panel-quick_login {
  background: #fcf9f4;
  width: 320px;
  height: 300px;
  padding: 30px;
  -webkit-box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  -moz-box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 100;
}

#mini-panel-quick_login h2 {
  margin: 0 0 15px 0;
  font-size: 24px;
  text-align: center;
  color: $brand-green;
}

#mini-panel-quick_login .pane-user-login h2 {
  border-top: 1px solid #aaaaaa;
  font-size: 18px;
  padding-top: 10px;
  text-align: left;
}

#mini-panel-quick_login .pane-user-login .item-list {
  display: none;
}

.reg_label {
  float: left;
  width: 120px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 14px;
}
.reg_text {
  float: left;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  padding: 5px;
  margin-bottom: 15px;
  width: 183px;
}
.reg_submit {
  margin-left: 120px;
  background: $brand-green;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 0;
  padding: 5px;
  color: #fff;
  font-size: 14px;
  -webkit-box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  -moz-box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
}
.reg_submit:hover {
  cursor: pointer;
  background: #547e54;
}

#mini-panel-quick_login #user-login-form .form-item-name {
  float: left;
  width: 120px;
  margin-top: 0;
}

#mini-panel-quick_login #user-login-form .form-item-name label {
  width: 100px;
  font-size: 14px;
}

#mini-panel-quick_login #user-login-form .form-item-name input {
  width: 100px;
  font-size: 14px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 5px;
  border: 1px solid #aaaaaa;
}

#mini-panel-quick_login #user-login-form .form-item-pass {
  float: left;
  width: 120px;
  margin-top: 0;
}

#mini-panel-quick_login #user-login-form .form-item-pass label {
  width: 100px;
  font-size: 14px;
}

#mini-panel-quick_login #user-login-form .form-item-pass input {
  width: 100px;
  font-size: 14px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 5px;
  border: 1px solid #aaaaaa;
}

#mini-panel-quick_login #user-login-form {
}

#mini-panel-quick_login #user-login-form #edit-actions input {
  position: absolute;
  right: 50px;
  bottom: 30px;
  background: $brand-green;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 0;
  padding: 5px;
  color: #fff;
  font-size: 14px;
  -webkit-box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  -moz-box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
  box-shadow: 0px 1px 2px rgba(1, 1, 1, 0.3);
}

.classified-expires {
  background: #fff;
  border: 1px solid #e0e0e0;
  color: #000;
  font-size: 12px;
}

.node-type-classified .submitted,
.node-type-classified .action-links {
  display: none;
}
.node-type-classified .field-name-classified-category {
  border-top: 1px solid #e0e0e0;
  float: left;
  width: 700px;
}

.node-type-classified .field-name-body {
  float: left;
  width: 700px;
}

.node-type-classified hr {
  background: #e0e0e0;
  margin: 5px 0;
}

#block-egc-renewals-membership-amount .content {
  background: #f8fff0 url("../img/icon-dollar.jpg") 7px center no-repeat;
  border: 1px solid #be7;
  padding: 10px 10px 10px 55px;
}

#block-egc-renewals-membership-amount .content p {
  margin: 0;
  font-size: 22px;
  font-family: "Droid Serif";
}

#block-block-10,
#block-block-11 {
  background: #eceadf;
  padding: 10px;
  text-align: center;
}

#block-block-10 h3,
#block-block-11 h3 {
  font-size: 22px;
  margin: 0;
  padding: 0;
  color: #8c9460;

  @include xs {
    font-size: 16px;
  }
}

#block-block-10 .content,
#block-block-11 .content {
  font-size: 14px;
  font-style: italic !important;

  @include xs {
    font-size: 12px;
  }
}

#block-block-10 .content p,
#block-block-11 .content p {
  margin-bottom: 0;
  font-size: 16px;
  @include xs {
    font-size: 12px;
  }
}

.pane-views-from-the-blog-block-1 .pane-content {
  text-align: left !important;
  border: 1px solid #b9d5d3;
  text-align: center;
  font-size: 14px;
  padding: 15px;
  font-weight: bold;
}

.page-profile-member .breadcrumb {
  display: none;
}

h4 {
  font-weight: normal;
  color: #666;
  font-size: 14px;
}

.page-profile-member #edit-submit,
.page-user-register #edit-submit {
  background: $brand-green;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: 0;
  color: #fff;
  font-size: 16px;
}

#map_item a {
  text-decoration: underline !important;
}

a.formtip {
  background: url("../img/help.png") no-repeat 0 0;
  background-size: 14px 28px;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 4px;
}

a.formtip:hover {
  background-position: 0 -14px;
  text-decoration: none;
}

.field-name-field-photos .fieldset-description {
  background: #f2f2f2;
  border: 1px solid #ddd;
  padding: 10px;
}

#images_notice {
  padding: 10px;
  text-align: center;
  border: 1px solid #be7;
  background-color: #f8fff0;
  margin-bottom: 15px;
}

#images_notice h3 {
  font-size: 14px;
  margin: 0 0 15px 0;
}

#images_notice .button {
  margin: 0;
}

#images_notice .button a {
  display: block;
  background: #006633;
  color: #fff;
  padding: 10px;
  margin: 20px auto;
  width: 200px;

  @include xs {
    @include box-sizing(border-box);
    width: 100%;
    max-width: 100%;
  }
}

#images_notice .note {
  font-size: 12px;
  font-style: italic;
  padding: 0;
  margin: 0;
}

div.edit-email-confirmation-wrapper {
  display: none;
}

.form-item-profile-member-field-host-address-und-0-re-geocode-location,
.form-item-profile-member-field-host-address-und-0-delete-location {
  display: none;
}

.admin-menu
  .form-item-profile-member-field-host-address-und-0-re-geocode-location,
.admin-menu .form-item-profile-member-field-host-address-und-0-delete-location {
  display: block;
}

.node-type-directory .breadcrumb,
.node-type-directory .tabs,
.node-type-directory h1,
.node-type-directory .action-links,
.node-type-directory #main > footer {
  display: none;
}

@include sm-max {
  #block-block-3 {
    display: none;
  }

  #block-block-1 {
    max-width: 100%;
    float: none;
  }
}

.tabs-wrapper {
  .tabs {
    li,
    li a {
      @include xs {
        display: block;
        float: none;
      }
    }
  }
}

@include sm-max {
  #container {
    @include box-sizing(border-box);
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }
}

.edit-user2-wrapper {
  display: none;
}

#block-block-10,
#block-block-11,
.pane-egc-renewals-expiration-date,
#images_notice {
  @include sm-max {
    display: none;
  }
}

body.sidebar-second #main,
body.sidebar-second #sidebar-second,
.form-textarea-wrapper {
  @include sm-max() {
    @include box-sizing(border-box);
    width: 100%;
    max-width: 100%;
  }
}

#user-pass,
#forgot-username-form,
#user-login {
  #edit-name,
  #edit-pass,
  #edit-email-address {
    border: 1px solid #999;
    color: #666;
    padding: 5px;
    font-size: 16px;

    &.error {
      border: 1px solid #e2e2e2;
      color: #666;
    }
  }
}
