$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

// Small screen / tablet

$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

@mixin lg() {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin md-min() {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin md() {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin md-max() {
  @media (max-width: $screen-md-max) {
    @content;
  }
}

@mixin sm-min() {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin sm() {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin sm-max() {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin xs() {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin xxs() {
  @media (max-width: 480px) {
    @content;
  }
}
