@include sm-max {
  $header-height: 105px;

  body {
    padding-top: $header-height;
  }

  body#locations-map {
    padding-top: 0;
  }

  #header {
    height: $header-height;
    position: fixed;
    z-index: 55;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    left: 0;

    #header_social {
      display: none;
    }

    #logo {
      position: relative;
      width: 100px;
      margin: 5px 0 0 5px;

      img {
        width: 100%;
        height: auto;
      }
    }

    #headerabove {
      height: auto;
      background: $brand-green;
      color: #fff;
      a {
        color: #fff;
      }
    }

    .headercontainer {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
    }

    #navigation {
      position: static;
      top: 0;
      right: 0;
      display: none;
    }
  }
}

#map-search {
  display: none;

  @include sm-max {
    @include border-radius(5px);
    background: $brand-green;
    padding: 5px 10px;
    color: #fff;
    border: 0;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    /*
		width: 48px;
		height: 59px;
*/
    position: absolute;
    top: 11px;
    right: 48px;
    display: block;
  }
}

#nav-toggle {
  display: none;

  @include sm-max {
    width: 48px;
    height: 59px;
    position: absolute;
    top: -3px;
    right: 0;
    display: block;

    span {
      position: absolute;
      left: 16px;
    }

    span,
    span:before,
    span:after {
      cursor: pointer;
      width: 17px;
      background: $brand-green;
      position: absolute;
      display: block;
      content: "";
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    span {
      top: 21px;
      height: 2px;
    }

    span:before {
      top: 5px;
      height: 2px;
    }

    span:after {
      top: 10px;
      height: 2px;
    }

    &.active {
      span {
        background-color: transparent;
        height: 0px;

        &:before,
        &:after {
          top: 5px;
          height: 2px;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

#mobile-only-links {
  display: none;

  @include sm-max {
    display: block;
    text-align: right;
    padding: 5px;
    font-size: 12px;

    a {
      @include border-radius(5px);
      padding: 5px 10px;
      display: inline-block;
      background: #558055;
      min-width: 50px;
      text-align: center;
    }
  }
}

#mobile-menu-wrapper {
  display: none;
  background: $brand-green;
  position: absolute;
  top: 60px;
  left: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: none;
  z-index: 1200;

  ul {
    margin: 0;
    padding: 15px 48px 0 48px;
    list-style: none;
    display: none;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 47px;
      border-bottom: 1px solid #558055;
      text-align: left;

      a {
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        display: block;
        transition: color 0.2s ease-in-out;
        color: #fff;
      }

      strong {
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        display: block;
        font-weight: normal;
      }

      &.child {
        border-bottom: 0;
        padding-left: 10px;

        &.last {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

#nav_internal {
  @include sm-max {
    display: none;
  }
}
