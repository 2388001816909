@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model;
	-moz-box-sizing: $box-model;
	box-sizing: $box-model;
}

@mixin box-shadow($box-model) {
	-webkit-box-shadow: $box-model;
	-moz-box-shadow: $box-model;
	box-shadow: $box-model;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {	
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  -webkit-transform: $args; 
  transform: $args; 
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin circle($width, $color) {
  width: $width;
  height: $width;
  background: $color;
	-webkit-border-radius: $width/2;
	-moz-border-radius: $width/2;
	border-radius: $width/2;
}

@mixin clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}