// This is a basic grid based on Boostrap elements for the purpose of the blog		
.row {
	@include clearfix;

	.col-xs-12, 
	.col-sm-6, 
	.col-md-4,
	.col-md-6,
	.col-lg-3,
	.col-lg-6 { 
		@include box-sizing(border-box);
		padding: 0 15px;
	}
	
	.col-xs-12 { 
		float: none;
		width: 100%;
	}
	@include sm {
		.col-sm-6 {
			width: 49%;	
			float: left;	
		}
	}
	
	@include md {
		.col-md-4 {
			width: 24%;		
			float: left;
		}
		
		.col-md-6 {
			width: 49%;		
			float: left;
		}
	}
	
	@include lg {
		.col-lg-3 {
			width: 33%;	
			float: left;				
		}		
			
		.col-lg-6 {
			width: 49%;	
			float: left;				
		}	
	}
}
