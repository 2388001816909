@include sm-max {

		#footer {
			#copyright,
			#footer_nav {
				float: none;
				padding: 0 0 15px 0;
				text-align: center;
				margin-bottom: 0;
			}			
		}

}