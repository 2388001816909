.front {
	.contentcontainer {
		@include sm-max {
			width: 100%;
			max-width: 100%;
			box-sizing: border-box;
			height: auto !important;
		}		
	}
	
	.view-image-slider {
		@include xs {
			height: $mobile-slider-height;
			.view-content {
				height: $mobile-slider-height !important;
			}
		}
	}
}

.ns_dots {
	z-index: 50 !important;
}
