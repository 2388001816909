.page-search {
	#search_location {
		@include sm-max {
			width: 100%;
			float: none;
			margin-bottom: 5px;
			margin-right: 0;
		}
		input {
			@include sm-max {
				@include border-radius(0);
				@include box-sizing(border-box);
				max-width: 100%;
				width: 100% !important;			
				font-size: 16px;
				margin-bottom: 10px;
				float: none;	
				margin-right: 0;
			}
		}	
		
		#search_distance,
		#search_max_guests {
			@include sm-max {
				@include box-sizing(border-box);
				width: 48%;
				margin-bottom: 10px;
			}	
			
			select {
				@include sm-max {
					width: 90%;
					font-size: 16px;
					margin-top: 0;
					padding-left: 10px;
				}
			}		
		}

		#search_distance {
			@include sm-max {
				margin-right: 2%;
			}
		}
		
		#search_max_guests {
			@include sm-max {
				margin-left: 2%;
			}
		}
		
	}
	
	#search_left_column,
	#search_right_column {
		@include sm-max {
			@include box-sizing(border-box);
			float: none;
			width: 100%;
		}
		
		fieldset {
			legend {
				@include sm-max {
					@include box-sizing(border-box);
					width: 100% !important;
					padding-right: 10px;
				}
			}
		}
	}
	
	#search_mode {
		.ui-button {
			width: 50%;
		}
	}
	
	#map-canvas {
		max-width: 100%;
	}
	
	#search_left_column { 
		#filter_wrapper { 
			@include sm-max {
				display: none; 
				
				fieldset {
					display: none;
				}
			}
		} 
	}
	
	#results-note {
		@include sm-max {
			@include box-sizing(border-box);
			max-width: 100%;
			span {
				font-size: 12px;				
				line-height: 14px;
			}

		}
	}
	
	#list-grid {
		max-width: 100% !important;
	}
	
	#more-button, #all-button {
		p {
			text-align: center;
			margin-top: 15px;
		}
	}
	
	#all-button p { font-size: 12px; }
	
	span#contact-info,
	span#directory-summary,
	span#contact-info,
	span#image-name,
	.search_info_summary,
	.search_info_street,
	.search_info_image
	 {
		@include sm-max {
			display: none !important;
		}
	}
	
	#advanced_filters,
	#clear {
		@include sm-max {
			@include box-sizing(border-box);
			@include border-radius(5px);
			float: left;
			width: 49%;
			font-size: 12px !important;
		}
	}
	
	#clear {
		@include sm-max {
			margin-right: 1%;	
		}
	}
	
	#advanced_filters {
		display: none;
		
		@include sm-max {
			display: block;
			margin-left: 1%;
		}
		background: #676796;
		color: #fff !important;
		padding: 5px;
		text-align: center;
		font-size: 13px;
		margin-bottom: 15px;
	}
}
