.page-lounge .pane-block-4 iframe,
.page-lounge .pane-block-4 iframe[style], 
.fb_iframe_widget, 
.fb_iframe_widget span, 
.fb_iframe_widget span iframe[style] {	 
	width: 100% !important;
}

@include sm-max {
	.pane-block-4, .fb_iframe_widget {
		display: none;
	}	
}
